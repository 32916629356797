const url = {
    dev: {
        // publicUrl: 'http://127.0.0.1:9511/admin',
        // manageUrl: 'http://127.0.0.1:9511/admin'
        publicUrl: 'https://api.yjwhsz.com/api/admin',
        manageUrl: 'https://api.yjwhsz.com/api/admin'
    },
    pro: {
        publicUrl: 'https://api.yjwhsz.com/api/admin',
        manageUrl: 'https://api.yjwhsz.com/api/admin'
    }
}

export const get = () => {
    if(process.env.NODE_ENV === 'production'){
        return url['pro']
    }else{
        return url['dev']
    }
}
